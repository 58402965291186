/* custom bootstrap extensions */
@import "./variables.scss";

/* - - - - - - - - - - A - - - - - - - - - - */
.alert {
  // TODO positioning and styling in general @ fixed is kinda messed up rn
  &.alert-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }
  &.alert-mm {
    color: $color-mm;
    background-color: white;
    border-color: $color-mm;
  }
}
/* - - - - - - - - - - B - - - - - - - - - - */
.bg {
  &-disabled { background-color: #e9ecef; }
  &-mm {
    background-color: $color-mm;
    &.bg-opacity-25 { background-color: rgba($color-mm, 0.25); }
  }
}
.border {
  &-black {
    border: thin solid black !important;
  }
  &-mm {
    border: thin solid $color-mm !important;
  }
  &-left-0 {
    border-left: none !important;
  }
}
.btn {
  &-mm {
    color: #fff !important;
    background-color: $color-mm !important;
    border-color: $color-mm !important;
    &:hover {
      color: #fff !important;
      background-color: lighten($color-mm, 2.5%) !important;
      border-color: lighten($color-mm, 2.5%) !important;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !important;
    }
    &[disabled] {
      color: #fff !important;
      background-color: $color-mm !important;
      border-color: $color-mm !important;
      opacity: 0.375 !important;
    }
    &:focus {
      color: #fff !important;
      background-color: lighten($color-mm, 2.5%) !important;
      border-color: lighten($color-mm, 2.5%) !important;
      box-shadow: $box-shadow-mm !important;
    }
  }

  &-outline-mm {
    color: $color-mm !important;
    border-color: $color-mm !important;
    background-color: #fff !important;
    &:hover {
      color: lighten($color-mm, 2.5%) !important;
      background-color: rgba($color-mm, 0.15) !important;
      border-color: lighten($color-mm, 5%) !important;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !important;
    }
    &[disabled] {
      color: $color-mm !important;
      border-color: $color-mm !important;
      background-color: #fff !important;
      opacity: 0.375 !important;
    }
    &:focus {
      color: lighten($color-mm, 2.5%) !important;
      background-color: rgba($color-mm, 0.15) !important;
      border-color: lighten($color-mm, 5%) !important;
      box-shadow: $box-shadow-mm !important;
    }
  }

  &.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ($spacer * .5);
  }
}
/* - - - - - - - - - - C - - - - - - - - - - */
.card {
  .card-body {
    overflow: auto;
  }

}
.cursor {
  &-pointer { cursor: pointer; }
}

.checkmark {
  color: $color-mm;
}

/* - - - - - - - - - - D - - - - - - - - - - */
.dropdown {
  &.w-100 {
    button {
      width: 100%;
    }
  }
  &-menu {
    padding: 0;
    hr { margin: 0; }
    a { padding: 16px; }
    &-mm {
      border-color: $color-mm;
      hr { border-color: $color-mm; }
      .dropdown-item {
        &:active, &:hover, &:focus {
          background-color: rgba($color-mm, 0.05);
          color: black;
        }
      }
    }
  }
  &-toggle {
    &.no-arrow {
      &::after {
        display: none !important;
      }
    }
  }
}
/* - - - - - - - - - - E - - - - - - - - - - */
/* - - - - - - - - - - F - - - - - - - - - - */
.flex {
  &-basis-auto { flex-basis: auto; }
}
.float {
  &-right { float: right !important; }
  &-left { float: left !important; }
  &-none { float: none !important; }
}
.font {
  &-weight {
    &-normal { font-weight: normal !important; }
    &-bold { font-weight: bold !important; }
  }
}
.form {
  &-check {
    display: flex;
    float: left;
    align-items: center;
    justify-content: center;
    &-label {
      margin-top: auto;
      padding-left: 0.75rem;
    }
    &-input {
      cursor: pointer;
      width: 54px !important;
      height: 28px !important;
      &:checked[variant="mm"] {
        background-color: $color-mm;
        border-color: $color-mm;
      }
      &:focus[variant="mm"] {
        &:not(:checked) {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ddd'/%3e%3c/svg%3e");
        }
        box-shadow: $box-shadow-mm;
        border-color: lighten($color-mm, 2.5%);
      }
    }
  }
  &-control, &-select {
    &:focus {
      border-color: $color-mm;
      box-shadow: $box-shadow-mm;
    }
  }
  &-floating {
    //label { }
  }
  &-control[type="time"], &-control[type="date"], &-select:not([disabled]) {
    cursor: pointer;
  }
}
/* - - - - - - - - - - G - - - - - - - - - - */
/* - - - - - - - - - - H - - - - - - - - - - */
/* - - - - - - - - - - I - - - - - - - - - - */
/* - - - - - - - - - - J - - - - - - - - - - */
.justify {
  &-self-center { justify-self: center; }
}
/* - - - - - - - - - - K - - - - - - - - - - */
/* - - - - - - - - - - L - - - - - - - - - - */
/* - - - - - - - - - - M - - - - - - - - - - */
/* - - - - - - - - - - N - - - - - - - - - - */
.nav-tabs {
  &-mm {
    .nav-link {
      color: rgb(15, 15, 15) !important;
      &.active {
        color: $color-mm !important;
      }
    }
  }
  &-light {
    .nav-link {
      color: rgb(80, 80, 80) !important;
      &.active {
        color: black !important;
      }
    }
  }
}
/* - - - - - - - - - - O - - - - - - - - - - */
.opacity {
  &-100 { opacity: 1.0; }
  &-75 { opacity: 0.75; }
  &-50 { opacity: 0.5; }
  &-25 { opacity: 0.25; }
}
/* - - - - - - - - - - P - - - - - - - - - - */
.pe {
  &-none { pointer-events: none; }
}
/* - - - - - - - - - - Q - - - - - - - - - - */
/* - - - - - - - - - - R - - - - - - - - - - */
/* - - - - - - - - - - S - - - - - - - - - - */
.sidebar {
  &-sticky {
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
/* - - - - - - - - - - T - - - - - - - - - - */
.text {
  &-mm { color: $color-mm; }
}
.tooltip {
  &:not([show]) {
    // yeet hidden tooltips into oblivion (required to fix scrollbar issues)
    position: absolute;
    top: -100vh;
  }
  overflow: clip;
  .tooltip-inner {
    background-color: #1d1d1d;
  }
  .tooltip-arrow:before {
    border-top-color: #1d1d1d;
  }
}
/* - - - - - - - - - - U - - - - - - - - - - */
/* - - - - - - - - - - V - - - - - - - - - - */
/* - - - - - - - - - - W - - - - - - - - - - */
.w {
  &-1 { width: 1% !important; }
  &-90 { width: 90% !important; }
}
.w-break {
  &-word { word-break: break-word; }
  &-all { word-break: break-all; }
}
/* - - - - - - - - - - X - - - - - - - - - - */
/* - - - - - - - - - - Y - - - - - - - - - - */
/* - - - - - - - - - - Z - - - - - - - - - - */