#App {
  .loading-spinner {
    z-index: 9999;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.75);
  }
}