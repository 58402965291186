@import '~react-pro-sidebar/dist/scss/styles.scss';

#Sidebar {
  height: 100%;

  hr { margin: 0; }
  li > div { padding: 16px 35px 16px 20px; }
  .mobile-button {
    z-index: 1000;
  }

  .pro-sidebar-header {
    color: #eee;
  }

  .pro-menu-item {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .pro-sidebar-footer, .pro-sidebar-header, .pro-sidebar-content {
    .pro-menu {
      padding: 0;
    }
  }
}
